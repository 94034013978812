import React, { useState } from 'react'

import iconX from '@cuidardigital/commons/assets/img/close.svg'
import ShowModal from '@cuidardigital/commons/components/Modal'
import { graphql, useStaticQuery } from 'gatsby'
import {
	Button,
	ButtonReturn,
	Buttons,
	Checkbox,
	CheckboxContainer,
	ContentWrapper,
	FormWrapper,
	Icon,
	Logo,
	Span
} from './styles'
import logo from '../../../assets/img/logo.png'
import { ModalWrapper } from '../../Modal/messageModal'

export interface IModalTour {
	onAccept: (args?: any) => void
	onDecline: (args?: any) => void
	doctorName: string
}

const ModalTour: React.FC<IModalTour> = ({ onAccept, onDecline, doctorName }) => {
	const [notPerturb, setNotPerturb] = useState(false)
	const data = useStaticQuery(pageQuery)
	const {
		title,
		description,
		button_skip,
		button_start,
		paragraph_check
	} = data.saudeDigital.metadata.tour_platform

	const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
		setNotPerturb(e.target.checked)
	}
	const handleDecline = () => {
		onDecline(notPerturb)
	}

	const handleExit = () => onDecline()
	return (
		<>
			{/* tslint:disable-next-line: jsx-no-lambda */}
			<ShowModal p='0' maxWidth='40vw' onClose={handleExit}>
				<Icon id='icon-digital-sign-close' src={iconX} onClick={handleDecline} />
				<ModalWrapper>
					<FormWrapper>
						{/* <Logo src={logo} alt='Logo do Cuidar Digital' /> */}
						<ContentWrapper>
							<Span bold fontSize='24px/24px' wrap>
								{title} {doctorName}
							</Span>
							<Span fontSize='14px/20pxs' wrap>
								{description}
							</Span>

							<br />
							<Buttons>
								<ButtonReturn
									id='button-tour-modal-skip'
									margin='10px 0 0 0'
									onClick={handleDecline}
								>
									{button_skip}
								</ButtonReturn>
								<Button id='button-tour-modal-start' onClick={onAccept}>
									{button_start}
								</Button>
							</Buttons>

							<CheckboxContainer>
								<Checkbox
									id='checkbox-not-perturb'
									type='checkbox'
									checked={notPerturb}
									onChange={handleCheckbox}
								/>
								<Span wrap fontSize='12px/12px' padding='0 0 0 3px' mb='2vh' display='inline'>
									{paragraph_check}
								</Span>
							</CheckboxContainer>
						</ContentWrapper>
					</FormWrapper>
				</ModalWrapper>
			</ShowModal>
		</>
	)
}

const pageQuery = graphql`
	query {
		saudeDigital: cosmicjsCuidarDigitalMarcas(slug: { eq: "bradesco" }) {
			metadata {
				tour_platform: tour_plataforma {
					title: titulo
					description: descricao
					button_skip: botao_pular_tour
					button_start: botao_iniciar_tour
					paragraph_check: paragrafo_check
				}
			}
		}
	}
`

export default ModalTour
