import React from 'react'
import { ButtonMobileMenu, ContainerMobile, SvgExamResult, SvgProntmed } from '../styles'

interface IProps {
	active: string
	onChange: (area: string) => void
}

const MobileMenuHome = ({ active, onChange }: IProps) => (
	<ContainerMobile>
		<ButtonMobileMenu
			id='button-mobile-menu-opened'
			width='50vw'
			active={active !== 'closed'}
			onClick={onChange('opened')}
		>
			<SvgProntmed />
		</ButtonMobileMenu>

		<ButtonMobileMenu
			id='button-mobile-menu-closed'
			width='50vw'
			active={active === 'closed'}
			onClick={onChange('closed')}
		>
			<SvgExamResult />
		</ButtonMobileMenu>
	</ContainerMobile>
)

export default MobileMenuHome
