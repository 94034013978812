import React, { useContext, useEffect, useState } from 'react'

import { Loader } from '@cuidardigital/commons/components/Loader'
import { logout } from '@cuidardigital/commons/services/auth'
import {
	getCertificatesValid,
	putUpdateDoctorOptins
} from '@cuidardigital/commons/services/certification.valid.services'
import { getDoctorInfo } from '@cuidardigital/commons/services/doctor.services'
import { mobilecheck } from '@cuidardigital/commons/utils/isMobile'
import { navigate } from 'gatsby'
import Dashboard from '../../components/Dashboard'
import MessageModal from '../../components/Modal'
import { initUserSession } from '../../utils/session'
import { store, TYPES } from '../../stores/app'
import ModalTour from '../../components/Tour/Modal'
import { TOUR } from '../../routes'

const Main = () => {
	// @ts-ignore
	const { state, dispatch } = useContext(store)

	const [systemInstability, setSystemInstability] = useState(false)
	const [openModalTour, setOpenModalTour] = useState(false)
	const [showDigitalCertificateModal, setShowDigitalCertificateModal] = useState(false)

	// @ts-ignore
	useEffect(() => {
		initUserSession(state, dispatch)
	}, [])

	const { session, doctorList, messageError, doctorCurrent } = state

	const handleTour = () => {
		setOpenModalTour(false)
		window.localStorage.setItem('TOURDONE', 'true')
		navigate(TOUR)
	}

	const uptadeOptinTourDone = (notPerturb: boolean) => {
		if (notPerturb === true) {
			putUpdateDoctorOptins(doctorCurrent.optins, 'TOURFEITO')
			dispatch({
				type: TYPES.SET_DOCTOR_CURRENT,
				payload: { ...doctorCurrent, optins: [...doctorCurrent.optins, 'TOURFEITO'] }
			})
		}
		setOpenModalTour(false)
		window.localStorage.setItem('TOURDONE', 'true')
	}

	const fecthDoctorInfo = async () => {
		const response = await getDoctorInfo(session.cpf)
		if (response && response.status === 200) {
			const hotInfo = response.data
			dispatch({
				type: TYPES.SET_DOCTOR_CURRENT,
				payload: hotInfo
			})
			return hotInfo
		}
	}

	const offerTour = (profile?: any) => {
		if (
			!mobilecheck() &&
			// !profile?.optins?.includes('TOURFEITO') &&
			!window.localStorage.getItem('TOURDONE')
		) {
			setOpenModalTour(true)
			return true
		}
	}

	if (session.isDoctor && session.cpf) {
		setTimeout(async () => {
			const doctorInfos = doctorCurrent.name ? doctorCurrent : await fecthDoctorInfo()
			if (offerTour(doctorInfos)) {
				return
			}

			if (
				!window.localStorage.getItem('showValidAd') &&
				doctorInfos &&
				!doctorInfos.optins.includes('NAOVERMAISADVALID')
			) {
				window.localStorage.setItem('showValidAd', 'true')
				getCertificatesValid(session.cpf)
					.then(res => {
						if (res?.data && !JSON.stringify(res.data || '').includes('"status":"S"')) {
							setShowDigitalCertificateModal(true)
							// dispatch({ type: TYPES.SET_USER_SESSION, payload: { valid: res.data.slots } })
						}
					})
					.catch(e => {
						console.log('certificates error', e)
					})
			}
		}, 1000)
	} else {
		// const callFetch = async () => {
		// 	// const secretaryInfos = state.profile.name ? state.profile : await fecthDoctorInfo()
		// 	const doctorInfos = doctorInfo.name ? doctorInfo : await fecthDoctorInfo()
		// 	offerTour(doctorInfos)
		// }
		// callFetch()
	}

	const tourFunc = () => {
		if (!openModalTour) return null
		return (
			<ModalTour doctorName={session?.name} onAccept={handleTour} onDecline={uptadeOptinTourDone} />
		)
	}

	// Modal informando de instabilidade
	if (systemInstability) {
		const handleCloseModal = () => setSystemInstability(false)
		return (
			<MessageModal
				onClose={handleCloseModal}
				title='Aviso importante'
				messageHTML='Nosso sistema de envio de alertas por e-mail e SMS está indisponível no momento. Seguimos trabalhando para reestabelecer os serviços. Por favor, utilize as informações disponíveis nos cards de agendamento para orientar seu paciente para as teleconsultas.</br></br>Agradecemos sua compreensão!'
				buttonText='Ir para Home'
			/>
		)
	}

	if (session.isDoctor) {
		return (
			<>
				{session.accessToken && (
					<Dashboard
						modalTour={tourFunc()}
						showDigitalCertificateModal={showDigitalCertificateModal}
					/>
				)}
			</>
		)
	}
	if (doctorList.length > 0) {
		return <>{session.accessToken && <Dashboard modalTour={tourFunc()} />}</>
	}
	if (messageError) {
		return (
			<MessageModal
				onClose={logout}
				title='Atenção'
				message={messageError}
				buttonText='Ok, entendi'
			/>
		)
	}
	return <Loader />
}

export default Main
