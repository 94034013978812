import Modal from '@cuidardigital/commons/components/Modal'
import React from 'react'
import styled from 'styled-components'
import { Button, ButtonReturn } from '../superSetCommons'
import { ModalSubtitle as Subtitle, ModalTitle, ModalWrapper } from './messageModal'

const ModalSubtitle = styled(Subtitle)`
	margin: 2vh 0 8vh 0;
`

const SubmitButton = styled(Button)`
	margin: 32px 0 0 4%;
	width: 48%;
`

const ConfirmResend = ({ setShowModal, onConfirm, type }) => {
	const closeModal = () => {
		setShowModal(false)
	}

	return (
		<Modal maxWidth='420px' padTop='20vh' zIndex='11' onClose={closeModal}>
			<ModalWrapper>
				<ModalTitle wrap bold fontSize='32px/40px'>
					Deseja reenviar link da consulta?
				</ModalTitle>
				<ModalSubtitle wrap fontSize='16px/24px'>
					Ao fazer isso o paciente receberá novamente o {type === 'sms' ? 'SMS' : 'e-mail'} para
					acessar a consulta.
				</ModalSubtitle>

				<ButtonReturn id='button-resend-back' onClick={closeModal}>
					Voltar
				</ButtonReturn>
				<SubmitButton id='button-resend-send' onClick={onConfirm}>
					Enviar
				</SubmitButton>
			</ModalWrapper>
		</Modal>
	)
}

export default ConfirmResend
